<template>
  <div class="container admin">
    <ShowOrders msg="Hej" />
  </div>
</template>

<script>
// @ is an alias to /src
import ShowOrders from "@/components/ShowOrders.vue";

export default {
  name: "Admin",
  components: {
    ShowOrders,
  },
};
</script>
