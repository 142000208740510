<template>
  <div class="orders">
    <div v-if="loggedIn">
      <Logout />
      <DataTable
        editMode="row"
        dataKey="id"
        ref="dt"
        v-model:selection="selectedOrders"
        :paginator="true"
        :rows="50"
        :scrollable="true"
        scrollHeight="50vh"
        :rowsPerPageOptions="[10, 50, 100]"
        :value="orders"
        :filters="filters"
        sortField="value.date"
        :sortOrder="-1"
        @row-select="onRowSelect"
        @row-unselect="onRowUnselect"
        :class="singleStatus ? '' : 'disable_selectAll'"
      >
        <template #header>
          <div>
            <Button
              :class="CSVClass"
              class="button"
              icon="pi pi-external-link"
              label="Exportér valgte til Excel-optimeret CSV"
              @click="exportXSL($event)"
            />
            <Button
              class="button button--pdf"
              icon="pi pi-external-link"
              label="Exportér valgte til PDF"
              @click="exportPDF($event)"
            />
          </div>
          <div class="p-dropdown-wrapper">
            <Dropdown
              class="p-dropdown--status"
              :options="statuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Ny status"
              v-model="newStatus"
              :showClear="true"
              :disabled="disableStatusChange"
              @change="changeStatus()"
            >
              <template #option="slotProps">
                <span :class="'status-' + slotProps.option.value">{{
                  slotProps.option.label
                }}</span>
              </template>
            </Dropdown>
          </div>
        </template>

        <!--  Select all -->
        <Column
          selectionMode="multiple"
          headerStyle="width: 50px"
          bodyStyle="width:50px"
          header="Vælg"
        ></Column>

        <!--  Ordrenummer -->
        <Column
          field="value.orderNumber"
          header="Ordrenr."
          :sortable="true"
          headerStyle="width:80px"
          bodyStyle="width:80px"
        >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.value.orderNumber }}
            </span>
          </template>

          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.orderNumber']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg"
            /> </template
        ></Column>

        <!--  Totalt antal stel -->
        <Column
          field="value.totalRacks"
          header="Stel i alt"
          headerStyle="width:80px"
          bodyStyle="width:80px"
        >
        </Column>

        <!--  Koder -->
        <Column
          headerClass="column--filter"
          field="value.codes"
          header="Stel med kode"
          :sortable="true"
          filterMatchMode="contains"
          headerStyle="width:180px"
          bodyStyle="width:180px"
        >
          <template #body="slotProps">
            <span class="order__codes">
              {{ slotProps.data.value.codes }}
            </span>
          </template>

          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.codes']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i koder"
            />
          </template>
        </Column>

        <!--  Ekstra A stel -->
        <Column
          field="value.extra"
          header="A-stel"
          headerStyle="width:80px"
          bodyStyle="width:80px"
        ></Column>

        <!--  Ekstra L stel -->
        <Column
          field="value.extraL"
          header="L-stel"
          headerStyle="width:80px"
          bodyStyle="width:80px"
        ></Column>

        <!--  Firmanavn -->
        <Column
          field="value.address.companyName"
          header="Firmanavn"
          headerStyle="width:130px"
          bodyStyle="width:130px"
          filterMatchMode="contains"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.address.companyName }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.address.companyName']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i firmanavn"
            />
          </template>
        </Column>

        <!--  Vej -->
        <Column
          headerClass="column--filter"
          field="value.address.address"
          header="Vej"
          filterMatchMode="contains"
          :sortable="true"
          headerStyle="width:130px"
          bodyStyle="width:130px"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.address.address }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.address.address']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i adresse"
            />
          </template>
        </Column>

        <!--  Postnr -->
        <Column
          headerClass="column--filter"
          field="value.address.zip"
          header="Postnr"
          filterMatchMode="contains"
          :sortable="true"
          headerStyle="width:100px"
          bodyStyle="width:100px"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.address.zip }}
            {{ slotProps.data.value.address.city }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.address.zip']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i postnr"
            />
          </template>
        </Column>

        <!--  Lokation -->
        <Column
          field="value.address.comment"
          filterMatchMode="contains"
          header="Kommentar til lokation"
          headerStyle="width:200px"
          bodyStyle="width:200px;word-break:break-word"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.address.comment }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.address.comment']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i kommentar"
            />
          </template>
        </Column>

        <!--  Telefon -->
        <Column
          field="value.phone"
          header="Telefon"
          :sortable="true"
          filterMatchMode="contains"
          headerStyle="width:110px"
          bodyStyle="width:110px"
          ><template #body="slotProps">
            {{ slotProps.data.value.phone }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.phone']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i telefon"
            /> </template
        ></Column>

        <!--  Email -->
        <Column
          field="value.email"
          header="Email"
          :sortable="true"
          filterMatchMode="contains"
          headerStyle="width:250px"
          bodyStyle="width:250px;overflow:hidden;word-break:break-all"
          ><template #body="slotProps">
            {{ slotProps.data.value.email }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.email']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i email"
            /> </template
        ></Column>

        <!--  Kommentar -->
        <Column
          field="value.comment"
          filterMatchMode="contains"
          header="Kommentar"
          headerStyle="width:200px"
          bodyStyle="width:200px"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.comment }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.comment']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i kommentar"
            />
          </template>
        </Column>

        <!--  Date -->
        <Column
          field="value.formattedDate"
          header="Dato"
          :sortable="true"
          filterMatchMode="contains"
          headerStyle="width:200px"
          bodyStyle="width:200px"
        >
          <template #body="slotProps">
            <span>{{ formatDateForCalendar(slotProps.data.value.date) }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.formattedDate']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i dato"
            />
          </template>
        </Column>

        <!--  Admin Kommentar -->
        <Column
          :exportable="false"
          field="value.adminComment"
          filterMatchMode="contains"
          header="Admin: kommentar"
          headerStyle="width:200px"
          bodyStyle="width:200px"
        >
          <template #body="slotProps">
            {{ slotProps.data.value.adminComment }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['value.adminComment']"
              class="p-inputtext p-component p-column-filter"
              placeholder="Søg i adminkommentar"
            />
          </template>
        </Column>

        <!--  Status -->
        <Column
          headerClass="column--filter"
          field="status"
          header="Status"
          filterMatchMode="equals"
          :sortable="true"
          headerStyle="width:150px"
          bodyStyle="width:150px"
        >
          <!--  Status body -->
          <template #body="slotProps">
            <Dropdown
              :class="
                'p-dropdown--status current-status' + slotProps.data.status
              "
              :options="statuses"
              optionLabel="label"
              optionValue="value"
              placeholder="Ny status"
              v-model="slotProps.data.status"
              :showClear="false"
              :id="slotProps.data.id"
              style="margin-left: 0"
              :ref="slotProps.data.id"
              @change="changeSingleStatus($event, slotProps.data)"
              
            >
              <template #option="slotProps">
                <span :class="'status-' + slotProps.option.value">{{
                  slotProps.option.label
                }}</span>
              </template>
            </Dropdown>
          </template>
          <!--  Status filter -->
          <template #filter>
            <Dropdown
              v-model="filters['status']"
              :options="statuses"
              placeholder="Vis status"
              class="p-column-filter p-column-filter--status"
              optionLabel="label"
              optionValue="value"
              :showClear="true"
              @change="showStatus"
            >
              <template #option="slotProps">
                <span :class="'status-' + slotProps.option.value">{{
                  slotProps.option.label
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <!-- Edit -->
        <Column :exportable="false" headerStyle="width:50px">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success button--edit"
              @click="editProduct(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="orderDialog"
      :style="{ width: '550px' }"
      header="Ordredetaljer"
      :modal="true"
      class="p-fluid"
    >
      <div class="dialog__fieldgroup">
        <h3>Stel</h3>
        <div class="dialog__field">
          <label for="name">Stel med kode - komma-separeret</label>
          <InputText
            id="code"
            v-model.trim="order.value.codes"
            autofocus
            type="text"
          />
        </div>
        <div class="dialog__field">
          <label for="name">Antal A-stel</label>
          <InputText
            id="extra"
            v-model.trim="order.value.extra"
            autofocus
            type="number"
            min="0"
          />
        </div>
        <div class="dialog__field">
          <label for="name">Antal L-stel</label>
          <InputText
            id="extraL"
            v-model.trim="order.value.extraL"
            autofocus
            type="number"
            min="0"
          />
        </div>
      </div>
      <div class="dialog__fieldgroup">
        <h3>Sted</h3>
        <div class="dialog__field">
          <label for="name">Firmanavn</label>
          <InputText
            id="name"
            v-model.trim="order.value.address.companyName"
            required="true"
            autofocus
            :class="{
              'p-invalid': submitted && !order.value.address.companyName,
            }"
          />
          <small
            class="p-error"
            v-if="submitted && !order.value.address.companyName"
            >Firmanavn skal udfyldes</small
          >
        </div>
        <div class="dialog__field">
          <label for="name">Vej + nr</label>
          <InputText
            id="address"
            v-model.trim="order.value.address.address"
            required="true"
            autofocus
            :class="{
              'p-invalid': submitted && !order.value.address.address,
            }"
          />
          <small
            class="p-error"
            v-if="submitted && !order.value.address.address"
            >Vej + nr skal udfyldes</small
          >
        </div>
        <div class="dialog__field">
          <label for="name">Postnr</label>
          <InputText
            id="zip"
            v-model.trim="order.value.address.zip"
            required="true"
            autofocus
            :class="{
              'p-invalid': submitted && !order.value.address.zip,
            }"
          />
          <small class="p-error" v-if="submitted && !order.value.address.zip"
            >Postnr skal udfyldes</small
          >
        </div>
        <div class="dialog__field">
          <label for="name">By</label>
          <InputText
            id="city"
            v-model.trim="order.value.address.city"
            required="true"
            autofocus
            :class="{
              'p-invalid': submitted && !order.value.address.city,
            }"
          />
          <small class="p-error" v-if="submitted && !order.value.address.city"
            >By skal udfyldes</small
          >
        </div>
        <div class="dialog__field">
          <label for="name">Kommentar</label>
          <InputText
            id="addressComment"
            v-model.trim="order.value.address.comment"
            autofocus
          />
        </div>
      </div>

      <div class="dialog__fieldgroup">
        <h3>Kontaktoplysninger</h3>
        <div class="dialog__field">
          <label for="name">Telefonnummer</label>
          <InputText
            id="phone"
            v-model.trim="order.value.phone"
            autofocus
            required="true"
            :class="{
              'p-invalid': submitted && !order.value.phone,
            }"
          />
          <small class="p-error" v-if="submitted && !order.value.phone"
            >Telefonnummer skal udfyldes</small
          >
        </div>
        <div class="dialog__field">
          <label for="name">E-mail</label>
          <InputText
            id="email"
            v-model.trim="order.value.email"
            autofocus
            type="email"
          />
        </div>
        <div class="dialog__field">
          <label for="name">Kommentar</label>
          <InputText
            id="constactComment"
            v-model.trim="order.value.comment"
            autofocus
          />
        </div>
      </div>
      <div class="dialog__fieldgroup">
        <h3>Admin kommentar</h3>
        <div class="dialog__field">
          <label for="name">Kommentar</label>
          <InputText
            id="adminComment"
            v-model.trim="order.value.adminComment"
            autofocus
          />
        </div>
      </div>
      <template #footer>
        <Button
          label="Fortryd"
          icon="pi pi-times"
          class="button--no"
          @click="hideDialog"
        />
        <Button
          label="Gem"
          icon="pi pi-check"
          class="button--yes"
          @click="saveProduct"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import Logout from "@/components/Logout.vue";
import ObjectUtils from "primevue/components/utils/ObjectUtils";
import Dialog from "primevue/dialog";

import { jsPDF } from "jspdf";

export default {
  name: "ShowOrders",
  components: {
    Logout,
    Dialog,
  },
  props: {
    msg: String,
  },

  data: function () {
    return {
      filters: {},
      orders: null,
      columns: null,
      selectedProduct: null,
      CSVClass: "",
      newStatus: null,
      disableStatusChange: true,
      statuses: [
        { label: "afventer", value: 0 },
        { label: "afhentet", value: 1 },
        { label: "exporteret", value: 3 },
        { label: "ugyldig", value: 4 },
        { label: "ikke tom", value: 5 },
        { label: "ikke fundet", value: 6 },
      ],
      disableChooseAll: true,
      selectedOrders: null,
      loggedIn: false,
      editingCellRows: {},
      editingRows: [],
      orderDB: "/orders",
      rackCountTotal: null,
      orderDialog: false,
      order: {},
      submitted: false,
      singleStatus: null,
    };
  },
  computed: {
    statusChangeBtnText() {
      return this.disableStatusChange
        ? "Vælg status"
        : "Klik for at ændre status på valgte ordrer";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedOrders(newSelected, oldSelected) {
      if (newSelected) {
        this.toggleStatusChangeButton();
      }
    },
  },
  originalRows: {},
  methods: {
    getOrders() {
      var orders = firebase.database().ref(this.orderDB);
      orders.on(
        "value",
        (snapshot) => {
          const data = snapshot.val();
          this.orders = this.formatOrders(data);
        },
        (errorObject) => {
          console.log(errorObject);
        }
      );
    },
    formatOrders(data) {
      let orderArray = [];
      if (data) {
        for (let [key, value] of Object.entries(data)) {
          orderArray.push({
            id: key,
            value: value,
          });
        }
        orderArray.forEach((order) => {
          order.value.date = new Date(order.value.timeStamp); //this.formatDate(order.value.timeStamp);
          order.value.formattedDate = this.formatDateForCalendar(
            new Date(order.value.timeStamp)
          ); //this.formatDate(order.value.timeStamp);
          order.value.totalRacks = this.getTotalRackCount(order);
          order.value.codes = this.formatCodes(order.value.codes);
          order.value.orderNumber = order.value.orderNumber
            ? order.value.orderNumber
            : "";
          if (order.value.address) {
            order.value.address.comment = order.value.address.comment
              ? order.value.address.comment.trim()
              : "";
          } else {
            order.value.address = {};
          }

          order.value.comment = order.value.comment
            ? order.value.comment.trim()
            : "";

          order.status = order.value.state;
          order.newStatus = null;

          order.value.adminComment = order.value.adminComment
            ? order.value.adminComment
            : "";
        });
      }
      return orderArray;
    },
    getTotalRackCount(order) {
      const lCount = order.value.extraL ? order.value.extraL : 0;
      const aCount = order.value.extra ? order.value.extra : 0;
      let total = parseInt(lCount) + parseInt(aCount);

      if (order.value.codes) {
        for (var i = 0; i < order.value.codes.length; i++) {
          if (order.value.codes[i] === "0000000000") {
            order.value.codes.splice(i, 1);
          }
        }
        total += order.value.codes.length;
      }

      return total;
    },
    // eslint-disable-next-line no-unused-vars
    onRowSelect(event) {
      this.toggleStatusChangeButton();
    },
    // eslint-disable-next-line no-unused-vars
    onRowUnselect(event) {
      this.toggleStatusChangeButton();
    },
    changeNewStatus() {
      this.toggleStatusChangeButton();
    },
    formatDateForCalendar(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      //return day + "-" + month + "-" + date.getFullYear();
      return date.getFullYear() + "-" + month + "-" + day;
    },
    formatCodes(codes) {
      let codeString = "";
      if (codes && Array.isArray(codes)) {
        codes.forEach((code, key, codes) => {
          if (Object.is(codes.length - 1, key)) {
            codeString += code + "";
          } else {
            codeString += code + ", ";
          }
        });
      }
      return codeString;
    },
    exportCSV() {
      this.$refs.dt.exportCSV({ selectionOnly: true });
    },

    exportXSL() {
      let data = this.selectedOrders;
      let csv = "sep=," + "\r\n";

      //headers
      const columns = this.$refs.dt.columns;

      let headerInitiated = false;
      for (let i = 0; i < columns.length; i++) {
        let column = columns[i];

        if (column.props?.exportable !== false && column.props?.field) {
          if (headerInitiated) csv += ",";
          else headerInitiated = true;

          csv += '"' + (column.props?.header || column.props?.field) + '"';
        }
      }

      //body
      if (data) {
        data.forEach((record) => {
          csv += "\n";
          let rowInitiated = false;
          for (let i = 0; i < columns.length; i++) {
            let column = columns[i];
            if (column.props?.exportable !== false && column.props?.field) {
              if (rowInitiated) csv += ",";
              else rowInitiated = true;

              let cellData = ObjectUtils.resolveFieldData(
                record,
                column.props?.field
              );

              if (column.props?.field === "id") {
                cellData = cellData.substring(1);
              } else if (column.props?.field.indexOf(".date") > -1) {
                cellData = this.formatDateForCalendar(cellData);
              }

              if (cellData != null) {
                if (this.exportFunction) {
                  cellData = this.exportFunction({
                    data: cellData,
                    field: column.props?.field,
                  });
                } else cellData = String(cellData).replace(/"/g, '""');
              } else cellData = "";

              csv += '"' + cellData + '"';
            }
          }
        });
      }

      if (window.navigator.msSaveOrOpenBlob) {
        let blob = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        navigator.msSaveOrOpenBlob(blob, "excel" + ".csv");
      } else {
        let link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);
        if (link.download !== undefined) {
          var uri = "data:text/csv;charset=utf-8," + escape(csv);
          link.href = uri;
          link.download = "orders.csv";
          link.click();
        } else {
          csv = "data:text/csv;charset=utf-8," + escape(csv);
          window.open(encodeURI(csv));
        }
        document.body.removeChild(link);
      }
    },
    exportPDF() {
      const vm = this;
      var doc = new jsPDF("p", "pt", "A4");
      let data = vm.selectedOrders;

      console.clear();

      //headers
      const columns = this.$refs.dt.columns;

      if (data) {
        data.forEach((record, index) => {
          let row = 3;
          let rowInitiated = false;
          for (let i = 0; i < columns.length; i++) {
            let column = columns[i];
            let text = "";
            if (column.props?.exportable !== false && column.props?.field) {
              if (rowInitiated) text += ",";
              else rowInitiated = true;

              let cellData = ObjectUtils.resolveFieldData(
                record,
                column.props?.field
              );

              if (cellData != null) {
                if (this.exportFunction) {
                  cellData = this.exportFunction({
                    data: cellData,
                    field: column.props?.field,
                  });
                } else cellData = String(cellData).replace(/"/g, '""');
              } else cellData = "";

              let yPos = row * 20;
              if (column.props?.field != "status") {
                //console.log(column.props);
                text = column.props?.header + ": " + cellData;

                if (column.props?.field.indexOf("formattedDate") > -1) {
                  yPos = 40;
                } else if (
                  column.props?.field.indexOf("extraL") > -1 ||
                  column.props?.field.indexOf("address.comment") > -1 ||
                  column.props?.field.indexOf("zip") > -1 ||
                  column.props?.field.indexOf("totalRacks") > -1
                ) {
                  row++;
                }

                if (
                  column.props?.field.indexOf("companyName") > -1 ||
                  column.props?.field.indexOf("address.address") > -1
                ) {
                  text = cellData;
                }

                doc.text(text, 40, yPos);
              }
            }
            row++;
          }

          if (index < data.length - 1) doc.addPage();
        });
      }

      doc.save("orders.pdf");
    },
    // getStatusLabel(status) {
    //   // console.log(status);
    //   const newStatus = this.statuses.find((e) => e.value === status);
    //   // console.log(newStatus);
    //   return newStatus.label;
    // },
    getStatusValue(status) {
      const newStatus = this.statuses.find((e) => e.value === status);
      return newStatus.value;
    },
    toggleStatusChangeButton() {
      if (this.selectedOrders !== null) {
        this.disableStatusChange =
          this.selectedOrders.length > 0 ? false : true;
      } else {
        this.disableStatusChange = true;
      }
    },

    changeStatus() {
      if (this.newStatus !== null && this.selectedOrders) {
        this.selectedOrders.forEach((selected) => {
          var updates = {};
          updates["/orders/" + selected.id + "/state/"] = this.newStatus;
          this.updateStatus(updates);
        });
        this.selectedOrders = null;
        this.newStatus = null;
        this.toggleStatusChangeButton();
      }
    },
    changeSingleStatus(event, data) {
      const newStatus = event.value;
      console.log(this.$refs[data.id])
      if (newStatus !== null && data.id) {
        console.log("changing")
        var updates = {};
        updates["/orders/" + data.id + "/state/"] = newStatus;
        this.updateStatus(updates);

        return;
      }
    },
      updateStatus(updates) {
        firebase
          .database()
          .ref()
          .update(updates, (error) => {
            if (error) {
              console.log(error);
            } else {
              //console.log("yay");
            }
          });
          return "tada"
      },
    showStatus(e) {
      console.log(e);
      this.singleStatus = e.value != null ? true : false;
      console.log(this.filters["status"]);
    },

    enableChooseAll(e) {
      this.enableChooseAll = e.value ? true : false;
    },

    editProduct(product) {
      this.order = { ...product };
      this.orderDialog = true;
    },
    hideDialog() {
      this.orderDialog = false;
      this.submitted = false;
      this.order = {};
    },
    saveProduct() {
      const vm = this;
      vm.submitted = true;
      let newCodes = [];

      if (vm.order.value.codes) {
        vm.order.value.codes.split(",").forEach((code) => {
          let codeString = code.trim();
          let codeNumber = parseInt(codeString);

          if (codeNumber) newCodes.push(codeNumber);
        });
      }

      if (newCodes.length < 1) newCodes.push("0000000000");

      if (
        vm.order.value.address.companyName &&
        vm.order.value.address.address &&
        vm.order.value.address.city &&
        vm.order.value.address.zip
      ) {
        var postData = {
          orderNumber: vm.order.value.orderNumber,
          address: vm.order.value.address,
          phone: vm.order.value.phone,
          email: vm.order.value.email,
          comment: vm.order.value.comment,
          codes: newCodes,
          extra: parseInt(vm.order.value.extra),
          extraL: parseInt(vm.order.value.extraL),
          state: vm.order.value.state,
          timeStamp: vm.order.value.timeStamp,
          adminComment: vm.order.value.adminComment,
        };
        var updates = {};
        //console.log(postData);
        updates[this.orderDB + "/" + this.order.id] = postData;

        vm.orderDialog = false;
        vm.order = {};
      }

      firebase.database().ref().update(updates);
    },
  },
  mounted() {
    this.loggedIn = this.$store.state.loggedIn;

    if (window.location.href.includes("localhost")) {
      //console.log("IN DEV");
      this.loggedIn = true;
    }

    if (this.loggedIn) {
      this.getOrders();
    } else {
      this.$router.replace("/login");
    }

  },
};
</script>

<style lang="scss">
.orders {
  overflow: hidden;
}
.filter-calendar {
  width: 350px;
}
.dialog__field {
  margin: 0 0 10px 0;
  &group {
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: solid 1px grey;
  }
}
.p-column-filter {
  &--status {
    position: absolute;
  }
}
.button--pdf {
  margin-left: 10px;
}
.p-datatable-scrollable-body {
  padding-bottom: 200px;
}
.disable_selectAll {
  .p-filter-column:first-of-type {
    pointer-events: none;
    /* opacity: 0.3; */
    .p-checkbox-box {
      border: 2px solid rgba(0, 0, 0, 0.05);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
