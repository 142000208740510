<template>
  <div class="logout" v-if="user">
    <span> {{ user.email }}</span
    ><button type="button" class="button" @click="logout">
      logout
    </button>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
export default {
  name: "Logout",
  data: function() {
    return {
      user: null,
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          firebase.auth().onAuthStateChanged(() => {
            this.$store.state.loggedIn = false;
            this.$router.push("/login");
          });
        });
    },
  },
  mounted() {
    this.user = this.$store.state.user;
  },
};
</script>
<style scoped lang="scss">
.logout {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  span {
    padding-bottom: 0.5rem;
  }
}
</style>
